<!-- 首页 -->
<template>
  <div class="index">
    <index></index>
    <scroll height="350px"></scroll>
    <digLibrary></digLibrary>
    <wraparound></wraparound>
    <characteristic></characteristic>
    <activity></activity>
    <dep></dep>
    <callus></callus>
    <foot></foot>
  </div>
</template>

<script>
import { Slider, SliderItem } from "vue-easy-slider";
import img1 from "../assets/imgs/1.jpg";
import img2 from "../assets/imgs/2.jpg";
import scroll from "./components/scroll.vue";
import digLibrary from "./components/digLibrary.vue";
import wraparound from "./components/wraparound.vue";
import characteristic from "./components/characteristic.vue";
import activity from "./components/activity.vue";
import dep from "./components/dep.vue";
import callus from "./components/callus.vue";
import foot from "./components/foot.vue";
import index from "./index";
export default {
  components: {
    Slider,
    SliderItem,
    scroll,
    digLibrary,
    wraparound,
    characteristic,
    activity,
    dep,
    callus,
    foot,
    index,
  },
  data() {
    return {
      imgList: [{ src: img1 }, { src: img2 }],
      search: "",
      radio: "1",
      tabList: [
        {
          name: "资源检索",
        },
        {
          name: "数字图书馆",
        },
        {
          name: "读者服务",
        },
        {
          name: "特色馆藏",
        },
        {
          name: "活动动态",
        },
        {
          name: "联系我们",
        },
      ],
      activeTab: "",
    };
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang="less" scoped></style>
